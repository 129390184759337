<template>
  <el-row type="flex" justify="center" align="middle" class="login-row">
    <el-col :xs="20" :sm="16" :md="12" :lg="6">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>{{ this.setPassaword }}</span>
        </div>
        <el-form class="login-form" :rules="rules" :model="model" ref="form">
          <el-form-item prop="password">
            <el-input
              v-model="model.password"
              :placeholder="$t('reset_password.password')"
              type="password"
              prefix-icon="el-icon-lock"
            ></el-input>
          </el-form-item>
          <el-form-item prop="confirmPassword">
            <el-input
              v-model="model.confirmPassword"
              :placeholder="$t('reset_password.confirm_password')"
              type="password"
              prefix-icon="el-icon-lock"
            ></el-input>
          </el-form-item>
          <el-form-item align="right">
            <el-button type="primary" block @click="handleResetPassword"
              >{{ $t("reset_password.submit") }}
            </el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  metaInfo() {
    return {
      title: this.forgetPassaword + " - " + this.CompanyName
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      model: {
        email: "",
        token: "",
        password: "",
        confirmPassword: ""
      }
    };
  },
  computed: {
    setPassaword() {
      let setPassaword = this.$t("reset_password.title");
      if (this.$route.query.isset === "1") {
        setPassaword = this.$t("reset_password.set");
      }
      return setPassaword;
    },
    forgetPassaword() {
      let forgetPassaword = "Forget Password";
      if (this.$route.query.isset === "1") {
        forgetPassaword = "Set Password";
      }
      return forgetPassaword;
    },
    rules() {
      const checkConfirmPassword = (rule, value, callback) => {
        if (value !== this.model.password) {
          callback(new Error(this.$t("register.please_same_verify_password")));
        } else {
          callback();
        }
      };

      return {
        password: [
          {
            required: true,
            message: this.$t("register.please_input_password"),
            trigger: "blur"
          }
        ],
        confirmPassword: [{ validator: checkConfirmPassword, trigger: "blur" }]
      };
    }
  },
  watch: {
    $route: "fetchGetParameters"
  },

  mounted() {
    this.fetchGetParameters();
  },

  methods: {
    ...mapActions("password", ["reset"]),

    fetchGetParameters() {
      this.model.email = this.$route.query.email;
      this.model.token = this.$route.query.token;
    },

    async handleResetPassword() {
      try {
        let valid = await this.$refs.form.validate();

        if (!valid) {
          return false;
        }
      } catch (e) {
        return e;
      }

      if (!this.model.email || !this.model.token) {
        this.$message({
          message: this.$t("reset_password.resend_mail"),
          type: "error"
        });

        return false;
      }

      await this.reset(this.model);

      await this.$message({
        message: this.$t("reset_password.reset_success"),
        type: "success"
      });

      await this.$router.push({ name: "Login" });
    }
  }
};
</script>

<style scoped>
.login-row {
  height: 80vh;
}

.el-card /deep/ .el-card__header {
  background: var(--themeColor);
}

.clearfix {
  color: white;
}
</style>
